import { MercariListingDetailsDraft, MercariColor } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { MercariConditionRadioOptions, MercariShippingOptionRadioOptions, sanitizeZodObject } from '@foxtail-dev/user-clients'
import React, { useMemo, useState } from 'react'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { Collapsible } from '../../../../components/common/Collapsible'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { RadioBoolGroupField } from '../../../../components/formik/RadioGroupField'
import { MarketSpecificFieldsProps } from './MarketSpecificFieldsMap'
import { FoxTextField } from '../../../../components/formik/FoxTextField'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'
import { MercariCategoryModal } from '../../../../modals/domainSpecific/MercariCategoryModal'
import { useTaxonomyProvider } from '../../../../lib/TaxonomyProvider'

export const MercariFields = (props: MarketSpecificFieldsProps) => {
    const { values, listingOperationKind } = props

    const taxonomyProvider = useTaxonomyProvider()
    const mercariTaxonomy = taxonomyProvider?.mercari

    const [modalOpen, setModalOpen] = useState<string>('none')
    const mercariDetails = useMemo(() => {
        return sanitizeZodObject(values, MercariListingDetailsDraft)
    }, [values])

    const requiredDetails = mercariDetails.requiredDetails
    const optionalDetails = mercariDetails.optionalDetails
    const mercariCategories = requiredDetails.categories

    const onCloseModal = () => {
        setModalOpen('none')
    }

    const doesCategoryRequireSizeById = useMemo(() => {
        if (!mercariCategories || !mercariTaxonomy) {
            return false
        }
        return mercariTaxonomy.base.doesCategoryRequireSizeById(mercariCategories)
    }, [mercariCategories, mercariTaxonomy])

    const sizeValues = useMemo(() => {
        if (!mercariCategories || !mercariTaxonomy) {
            return []
        }
        return mercariTaxonomy.base.getSizeValuesForCategoryByIds(mercariCategories)
    }, [mercariCategories, mercariTaxonomy])

    return (
        <>
            <DetailSelectionField
                name='domainSpecificDetails.mercari.requiredDetails.categories'
                label='Category'
                placeholder='Select category'
                onDetail={() => setModalOpen('MercariCategory')}
            />
            <DomainSpecificDetailModal open={modalOpen === 'MercariCategory'} title={'Category'} closeModal={onCloseModal}>
                <MercariCategoryModal closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            {doesCategoryRequireSizeById && sizeValues.length > 0 && (
                <>
                    <DetailSelectionField
                        name='domainSpecificDetails.mercari.requiredDetails.size'
                        label='Size'
                        placeholder='Select size'
                        options={sizeValues}
                        onDetail={() => setModalOpen('MercariSize')}
                    />
                    <DetailSelectionModal
                        open={modalOpen === 'MercariSize'}
                        headerTitle={'Size'}
                        options={sizeValues}
                        paramName={'size'}
                        domainName={'mercari'}
                        isRequired={true}
                        currentValue={requiredDetails.size}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                    />
                </>
            )}

            <FoxTextField
                name='domainSpecificDetails.mercari.requiredDetails.brand'
                label='Brand'
                placeholder='Your brand'
                value={requiredDetails.brand}
                logOnBlur
            />

            <DetailSelectionField
                name='domainSpecificDetails.mercari.requiredDetails.condition'
                label='Condition'
                placeholder='Select condition'
                options={MercariConditionRadioOptions}
                onDetail={() => setModalOpen('MercariCondition')}
            />
            <DetailSelectionModal
                open={modalOpen === 'MercariCondition'}
                headerTitle={'Condition'}
                options={MercariConditionRadioOptions}
                paramName={'condition'}
                domainName={'mercari'}
                isRequired={true}
                currentValue={requiredDetails.condition}
                closeModal={onCloseModal}
                listingOperationKind={listingOperationKind}
            />

            <DetailSelectionField
                name='domainSpecificDetails.mercari.requiredDetails.shippingSelection'
                label='Shipping selection'
                placeholder='Select shipping option'
                options={MercariShippingOptionRadioOptions}
                onDetail={() => setModalOpen('MercariShippingOption')}
            />
            <DetailSelectionModal
                open={modalOpen === 'MercariShippingOption'}
                headerTitle={'Shipping Option'}
                options={MercariShippingOptionRadioOptions}
                paramName={'shippingSelection'}
                domainName={'mercari'}
                isRequired={true}
                currentValue={requiredDetails.shippingSelection}
                closeModal={onCloseModal}
                listingOperationKind={listingOperationKind}
            />

            {requiredDetails?.shippingSelection !== 'SOYO' && (
                <RadioBoolGroupField
                    label='Who pays for shipping'
                    name='domainSpecificDetails.mercari.requiredDetails.freeShipping'
                    iconType='Radio'
                    options={[
                        [true, "I'll pay"],
                        [false, 'Buyer pays']
                    ]}
                    logOnChange
                />
            )}

            <Collapsible label='More Details' buttonStyle={{ fontWeight: 'bold' }}>
                <>
                    <DetailSelectionField
                        name='domainSpecificDetails.mercari.optionalDetails.color'
                        label='Color'
                        placeholder='Select color'
                        options={MercariColor.options}
                        onDetail={() => setModalOpen('MercariColor')}
                        titleSubtext='(optional)'
                    />
                    <DetailSelectionModal
                        open={modalOpen === 'MercariColor'}
                        headerTitle={'Color'}
                        options={MercariColor.options}
                        paramName={'color'}
                        domainName={'mercari'}
                        isRequired={false}
                        currentValue={optionalDetails.color}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                    />
                </>
            </Collapsible>
        </>
    )
}

const styles = {
    containerValidation: {
        marginLeft: 5,
        marginTop: 4
    },
    textError: {
        fontSize: 12,
        lineHeight: 16
    }
}
